<template>
  <li v-if="link.sublinks.length == 0" class="nav-item toolbar-link" @click="openSubLinkOrRedirect(link)" style="color: #fff !important;">
    <a class="nav-link active topmenu-link" aria-current="page" data-bs-dismiss="offcanvas" style="color: #fff !important;"> <i
        class="canvas-item-icon mobile-activated" :class="link.icon_style"></i> {{ link.title.en }}</a>
  </li>

  <li v-if="link.sublinks.length > 0" class="mobile-deactivated" style="color: #fff !important;">
    <a class="nav-link dropdown-toggle topmenu-link" href="#" id="navbarDropdownMenuLink" role="button" style="color: #fff !important;"
       data-bs-toggle="dropdown" aria-expanded="false" >
      {{ link.title.en }}
    </a>
    <div class="dropdown-menu  dropdown-desktop "
         aria-labelledby="navbarDropdownMenuLink">
      <div v-for="sublink in link.sublinks" :key="sublink.id"><a @click="openSubLinkOrRedirect(sublink)"
                                                           class="dropdown-item" style=""
                                                           data-bs-dismiss="offcanvas">{{ sublink.title.en }}</a>
      </div>
    </div>
  </li>
</template>
<script>
import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
import {addIcons} from "ionicons";
import {
  personCircleOutline,
  chatbubbleOutline,
  syncOutline,
  logOutOutline,
  logInOutline,
  searchCircleOutline,
  homeOutline,
  chevronBackOutline,
  cloudOfflineOutline,
  menuOutline
} from "ionicons/icons";

addIcons({
  "sync-outline": syncOutline,
  "person-circle-outline": personCircleOutline,
  "log-out-outline": logOutOutline,
  "log-in-outline": logInOutline,
  "chatbubble-outline": chatbubbleOutline,
  "search-circle-outline": searchCircleOutline,
  "home-outline": homeOutline,
  "chevron-back-outline": chevronBackOutline,
  'cloud-offline-outline': cloudOfflineOutline,
  "menu-outline": menuOutline,
});
const config = require('@/config')
import router from '@/router';

export default {
  components: {},
  props: ['link'],
  data() {
    return {
      menulinks: [],
      menulinksTop: [],
      title: config.full_name,
      isConsultant: false,
      userConsultant: null,
      isDesktop: null,
      isIos: null,
      video_chat_enabled: false,
      confDate: config.conf_date,
      openSubBar: false,
      sublinks: null,
      time: null,
      appointmentsRole: null,
      searchterm: '',
      hideSearchBar: false,
      historyCount: 0,
      loginAlertOpen: false,
      onLine: navigator.onLine,
      showBackOnline: false
    }
  },
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapGetters('auth', ['user']),
    ...mapState('splashscreen', ['showSplashscreen']),
    ...mapState('personalDB', ['unreadNews']),
    ...mapState('chat', ['currentChat', 'unreadTotal']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion', 'primaryColor', 'menuheader']),


  },
  methods: {
    ...mapActions("settings", [
      "getSetting",
    ]),
    ...mapActions('sync', ['start']),
    ...mapActions("exhibitors", ["getAllExhibitors"]),
    ...mapActions('menulinks', ['isExternalLink']),
    ...mapActions('auth', ['GET_USERDATA']),
    ...mapMutations('splashscreen', ['setShowSplash']),
    ...mapActions("personalDB", ["connectPersonalDB", 'getItem', 'getItems', 'prepareFavoriteList', 'setTime', 'getUnreadNews']),
    ...mapMutations('personalDB', ['setUnreadNews']),
    ...mapActions('chat', ['getUnreadMessagesTotalCount']),
    ...mapActions('googleanalytics', ['trackWithLabel']),

    async openSubLinkOrRedirect(link) {

      this.trackWithLabel('menu_link:' + link.title.en)
      console.log('LINKS',link.sublinks)
      if (link.sublinks && link.sublinks.length > 0) {

        this.openSubBar = !this.openSubBar;
        if (this.sublinks == []) {
          this.sublinks = link.sublinks
        } else {
          this.sublinks = [];
        }
      } else {
        let external = await this.isExternalLink(link.target)
        if (external) {
          window.open(link.redirect, '_blank').focus();
        } else {
          router.push(link.redirect);
        }
      }
    },

  },

  mounted() {
  },
  beforeUnmount() {
  },

  watch: {}

};
</script>
<style lang="scss">
.topmenu-link{

}
</style>
