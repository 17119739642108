<template>
  <div class="menubar">
    <template v-for="link in menulinks" :key="link.id">
      <a
          @click="openLink(link)"
          class="menubar-item"
          style="position:relative;">
        <i class="menubar-item-icon" :class="link.icon_style" :style="{'color':link.color || primaryColor}"></i>
        <span class="text" v-html="link.title['en']"
              :style="{'color':link.color || primaryColor}"></span>
        <span v-if="link.target == '#/app/posts' && unreadNews > 0"
              class="position-absolute translate-middle badge rounded-pill bg-primary"
              style="top:15px; right:0px">
                {{ unreadNews }}
              </span>
      </a>
    </template>

  </div>

</template>
<script>
import {mapState, mapActions, mapGetters} from "vuex";

import router from '@/router';

export default {
  components: {},
  data() {
    return {
      menulinks: [],
    }
  },
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapGetters('auth', ['user']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),
  },
  methods: {
    ...mapActions("settings", [
      "getSetting",
    ]),
    ...mapActions('menulinks', ['getMenuBarItems','isExternalLink']),
    ...mapActions('auth', ['GET_USERDATA']),
    ...mapActions('googleanalytics', ['trackWithLabel']),

    async prepareLinks() {
      this.menulinks = (await this.getMenuBarItems()).sort((a, b) => a.order - b.order);
      console.log('enhancedlink',this.menulinks)
    },
    async openLink(link) {
      this.trackWithLabel('menu_link:' + link.title.en)
      let external = await this.isExternalLink(link.redirect)
      if (external) {
        window.open(link.redirect, '_blank').focus();
      } else {
        router.push(link.redirect);
      }
    },

  },
  async created() {
    await this.prepareLinks();
  },

  mounted() {
  },
  beforeUnmount() {
  },

  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        this.prepareLinks();
      }
    },
  },

};
</script>
<style lang="scss">
.menubar {
  height: 60px;
  display: flex;
  justify-content: center;
  background: #9f1854; //esaic2023 color

  .menubar-item {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 20px;
    color: #ffc75d; //esaic2023 color
    text-decoration: none;
    margin: 0 auto;

    .text {
      font-size: 0.8rem;
      display: block;
      margin-top: -15px;
    }

    .menubar-item-icon {
      font-size: 1.6rem;
      display: block;
    }

    .icon-sponsor, .icon-aussteller {
      width: 40px;
      font-size: 1.6rem !important;
      margin: 8px 0;
    }

    .icon-aussteller-new {
      width: 50px;
      font-size: 1.6rem !important;
      background-size: 60px 60px !important;
    }
  }
}
</style>
