import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import {IonicVueRouter} from "@ionic/vue";
import {IonicVue} from '@ionic/vue';
import axios from 'axios'
import moment from 'moment'
//import RTCMultiConnection from 'rtcmulticonnection'
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
import WebRTC from 'vue-webrtc'
import  'bootstrap'
import Vue3Analytics from 'vue3-analytics';




/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import {store} from "./store/index";

import {InteractiveSocket, PushSocket} from './plugins/vue-socket'

import { library,dom } from '@fortawesome/fontawesome-svg-core'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';

library.add(fas,far,fal,fad);
dom.watch();
import VueGtag from "vue-gtag";



const app = createApp(App);
/*app.use(Vue3Analytics, {
    id: 'UA-109856828-3', router
});*/
app.use(VueGtag, {config: { id: "G-3QX77GQY01" }}, router);



app.config.productionTip = false
//app.use(FontAwesomeIcon)
app.use(WebRTC)
app.use(moment);
app.use(IonicVueRouter);
app.use(IonicVue);
app.use(store);
app.use(router);
axios.defaults.headers.common['x-api-key'] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRlIjoiMjAyMC0xMS0wM1QxMjozODoyMi45OTZaIiwiaWQiOjMxMTg4LCJpYXQiOjE2MDQ0MDcxMDJ9.gNeE9SEAD6QMYqevWPhGSf0_gcDi6fGRY1yihQAt3AE";

app.config.globalProperties.axios = axios;

app.config.warnHandler = function () {
    return null
  }
const sockUrlInt = 'https://interactive-server.documedias.com'
const sockUrl = 'https://push.mobile.documedias.systems';

const options = {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 99999
}

app.use(PushSocket, {connection:sockUrl, options});
app.use(InteractiveSocket, {connection: sockUrlInt,options});


router.isReady().then(() => {
    app.mount('#app');
    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.mediaDevices.getUserMedia();

});
defineCustomElements(window);

import BaseLayout from './components/BaseLayout.vue';
import Banner from './components/Banner.vue';
import Header from '@/components/Header.vue';
import MenuBar from "@/components/MenuBar";
import Branding from "@/components/Branding";
// code hidden for display purpose
app.component('base-layout',BaseLayout);

app.component('banner',Banner);
app.component('header-bar',Header);
app.component('menu-bar',MenuBar);
app.component('font-awesome-icon',FontAwesomeIcon )
app.component('branding',Branding )



const PusherJS = require('pusher-js');
PusherJS.logToConsole = true;
let client = new PusherJS('pezsxnz54a35jjhc', {
    wsHost: 'broadcaster.documedias.systems',
    wsPort: 443,
    httpHost: '127.0.0.1',
    httpPort: 8030,
    forceTLS: false,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authEndpoint: 'http://localhost:8030/api/broadcasting/auth',
    auth: {
        headers: {
            'x-api-key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRlIjoiMjAyMS0wNC0xNVQwNTo0Mjo0MS41MTBaIiwiaWQiOjcsImlhdCI6MTYxODQ2NTM2MX0.0ssQOrA2s-KcaURqKXhuAMRKySfTL6rCCQximtUZfgg',
        }
    }
});

app.config.globalProperties.chatSocket = client;



