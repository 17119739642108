<template>

  <li v-if="link.sublinks.length == 0 && link.type!='item item-divider'" class="nav-item toolbar-link" @click="openSubLinkOrRedirect(link)"
      style="width: 200px;position:relative">
    <a class="nav-link active" aria-current="page" data-bs-dismiss="offcanvas" style="min-width:300px"
       :style="{'color':primaryColor + '!important'}"> <i
        class="canvas-item-icon mobile-activated" :class="link.icon_style"></i> {{ link.title[currentLanguage] }} </a><span
      v-if="link.target == '#/app/posts' && unreadNews > 0"
      class="position-absolute end-1   translate-middle badge rounded-pill bg-primary"
      style="top:20px">
                {{ unreadNews }}
              </span>
  </li>
  <li v-if="link.sublinks.length > 0 && link.type!='item item-divider'" class="mobile-activated">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
       data-bs-toggle="dropdown" aria-expanded="false" style="margin-left:20px;"
       :style="{'color':primaryColor + '!important'}">
      <i class="canvas-item-icon mobile-activated" :class="link.icon_style"></i>
      <div style="display:inline-block; width:210px; line-height:1rem;  white-space: normal;margin-left:4px"> {{ link.title.en }}</div>
    </a>
    <div style="box-shadow: none !important" class="dropdown-menu toolbar-submenu ms-2"
         aria-labelledby="navbarDropdownMenuLink">
      <div v-for="sublink in link.sublinks" :key="sublink.id">
        <a @click="openSubLinkOrRedirect(sublink)"
           style="margin-left: 20px; white-space: normal;max-width: 250px;"
           :style="{'color':primaryColor + '!important'}"
           class="dropdown-item"
           data-bs-dismiss="offcanvas">{{ sublink.title.en }} </a>
        <hr class="dropdown-divider mobile-activated" style="border-top:none">
      </div>
    </div>
  </li>

  <li v-if="link.type=='item item-divider'" class="menu-divider mobile-activated">
    <b> {{ link.title[currentLanguage] }} </b>
  </li>

</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
import {addIcons} from "ionicons";
import {
  personCircleOutline,
  chatbubbleOutline,
  syncOutline,
  logOutOutline,
  logInOutline,
  searchCircleOutline,
  homeOutline,
  chevronBackOutline,
  cloudOfflineOutline,
  menuOutline
} from "ionicons/icons";

addIcons({
  "sync-outline": syncOutline,
  "person-circle-outline": personCircleOutline,
  "log-out-outline": logOutOutline,
  "log-in-outline": logInOutline,
  "chatbubble-outline": chatbubbleOutline,
  "search-circle-outline": searchCircleOutline,
  "home-outline": homeOutline,
  "chevron-back-outline": chevronBackOutline,
  'cloud-offline-outline': cloudOfflineOutline,
  "menu-outline": menuOutline,
});
const config = require('@/config')
import router from '@/router';

export default {
  components: {},
  props: ['link'],
  data() {
    return {
      menulinks: [],
      menulinksTop: [],
      title: config.full_name,
      isConsultant: false,
      userConsultant: null,
      isDesktop: null,
      isIos: null,
      video_chat_enabled: false,
      confDate: config.conf_date,
      openSubBar: false,
      sublinks: null,
      time: null,
      appointmentsRole: null,
      searchterm: '',
      hideSearchBar: false,
      historyCount: 0,
      loginAlertOpen: false,
      onLine: navigator.onLine,
      showBackOnline: false
    }
  },
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapGetters('auth', ['user']),
    ...mapState('splashscreen', ['showSplashscreen']),
    ...mapState('personalDB', ['unreadNews']),
    ...mapState('chat', ['currentChat', 'unreadTotal']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion', 'primaryColor', 'menuheader']),
  },
  methods: {
    ...mapActions("settings", [
      "getSetting",
    ]),
    ...mapActions('sync', ['start']),
    ...mapActions("exhibitors", ["getAllExhibitors"]),
    ...mapActions('menulinks', ['isExternalLink']),
    ...mapActions('auth', ['GET_USERDATA']),
    ...mapMutations('splashscreen', ['setShowSplash']),
    ...mapActions("personalDB", ["connectPersonalDB", 'getItem', 'getItems', 'prepareFavoriteList', 'setTime', 'getUnreadNews']),
    ...mapMutations('personalDB', ['setUnreadNews']),
    ...mapActions('chat', ['getUnreadMessagesTotalCount']),
    ...mapActions('googleanalytics', ['trackWithLabel']),


    async openSubLinkOrRedirect(link) {
      this.trackWithLabel('menu_link:' + link.title.en)

      if (link.sublinks && link.sublinks.length > 0) {

        this.openSubBar = !this.openSubBar;
        if (this.sublinks == []) {
          this.sublinks = link.sublinks
        } else {
          this.sublinks = [];
        }
      } else {
        let external = await this.isExternalLink(link.target)
        if (external) {
          window.open(link.redirect, '_blank').focus();
        } else {
          router.push(link.redirect);
        }
      }
    },
  },

  mounted() {
  },
  beforeUnmount() {
  },

  watch: {}

};
</script>

