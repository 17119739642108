<template>
  <ion-app>
    <Menu></Menu>
  </ion-app>
</template>

<script>

import {IonApp, toastController} from "@ionic/vue";
import {defineComponent} from "vue";
import Menu from "./components/Menu.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import * as icon from "ionicons/icons";
import {addIcons} from "ionicons";
import {PushNotifications} from '@capacitor/push-notifications';
import {FCM} from '@capacitor-community/fcm';
import {isPlatform} from '@ionic/vue';
import axios from 'axios';
import moment from 'moment'
import {StatusBar} from '@capacitor/status-bar';
import { LocalNotifications} from '@capacitor/local-notifications';
//import { SplashScreen } from '@capacitor/splash-screen';


const config = require('@/config');

addIcons({"sync-outline": icon.syncOutline});
addIcons({"bulb-outline": icon.bulbOutline});
addIcons({"time-outline": icon.timeOutline});
addIcons({"calendar-outline": icon.calendarOutline});
addIcons({"people-outline": icon.peopleOutline});
addIcons({"heart-half-outline": icon.heartHalfOutline});
addIcons({"help-outline": icon.helpOutline});
addIcons({"globe-outline": icon.globeOutline});
addIcons({"notifications-outline": icon.notificationsOutline});
addIcons({"qr-code": icon.qrCode});
addIcons({"scan": icon.scan});

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    Menu,

  },
  data() {
    return {
      updateInterval: null,
      toast: null,
      //fcm: new FCM()
      browserName: null,
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('news', ['newsNumber']),
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapState('personalDB', ['unreadNews']),
    ...mapState('sessions', ['activeSessions']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion'])


  },
  methods: {
    ...mapActions('sync', ['start', 'showSyncToast']),

    ...mapMutations('pushmessages', ['addMessage']),
    ...mapMutations('news', ['addNewsNumber']),
    ...mapActions("settings", [
      "getSetting",
    ]),
    ...mapMutations('googleanalytics', ['setVM']),
    ...mapActions('chat', ['getRoomList', 'getUnreadMessagesTotalCount', 'markMessageAsRead']),
    ...mapActions("personalDB", ['getItem', 'setTime', 'getUnreadNews']),
    ...mapMutations("personalDB", ['setUnreadNews']),
    ...mapMutations('sessions', ['setActiveSessions']),


    async showUpdateToast() {
      this.toast = await toastController
          .create({
            message: 'A new version is available',
            position: 'bottom',
            duration: 2000,
            animated: 'true',
            buttons: [
              {
                side: 'start',
                icon: 'star',
                text: 'Update',
                handler: async () => {                
                  this.toast.dismiss();
                  let res = await this.start();
                  if (res) {
                    this.showSyncToast();
                    //this.$router.go();
                  }

                }
              }, {
                text: 'Done',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                }
              }
            ]
          })
      return this.toast.present();
    },
    //LOCAL NOTIFICATIONS METHODS
    async registerLocalNotificationTypes(){
      await LocalNotifications.registerActionTypes({
        types: [
          {
            id: 'your_choice',
            actions: [
              {
                id: 'dismiss',
                title: 'Dismiss',
                destructive: true
              },
              {
                id: 'open',
                title: 'Open app'
              },
              {
                id: 'respond',
                title: 'Respond',
                input: true
              }
            ]
          }
        ]
      });
    },

    async addLocalListeners(){
       await LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
        console.log('Notification action received', notification.actionId);
      });
    },
    
    //PUSH NOTIFICATIONS METHODS

    async addListeners() {
      await PushNotifications.addListener('registration', token => {

        //send to server

        let params = {}
        let platform = 'web';

        if (isPlatform('ios')) {
          platform = 'ios'
        }

        if (isPlatform('android')) {
          platform = 'android'
        }

        params = {'type': platform, 'token': token.value}

        if (this.user) {
          if (this.user.id) {
            axios.post('https://push.mobile.documedias.systems/subscribe/', {
              conference: this.currentConference,
              user: this.user.id,
              platform: params.type,
              token: params.token,
              application: this.fullName,
            });
          }
        } else {
          axios.post('https://push.mobile.documedias.systems/subscribe/', {
            conference: this.currentConference,
            platform: params.type,
            token: params.token,
            application: this.fullName,
          });
        }
      });

      await PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
      });

      await PushNotifications.addListener('pushNotificationReceived', notification => {
        //alert(notification)
        let nowTime = moment().toISOString()
        let msg = {
          time: nowTime, //moment
          content: notification.body || notification.data.body,
          redirect: notification.data.redirect || '/app/posts/',
          linkText: 'Go to news',
          //from: message.userFrom.firstname + ' ' + message.userFrom.lastname,
          //avatar: message.userFrom.avatar_id ? 'https://fileserver.documedias.systems/file/' + message.userFrom.avatar_id + '/face/130/130' : '',
          message: {
            title: notification.title
          }
        }
        console.log(msg)
        //this.addMessage(msg)
      });


      await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
        this.$router.push(notification.notification.data.redirect || '/app/posts/');
      });
    },

    async registerNotifications() {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        console.log('User denied permissions!');
      }

      await PushNotifications.register();
    },

    async getDeliveredNotifications() {
      const notificationList = await PushNotifications.getDeliveredNotifications();
      console.log('delivered notifications', notificationList);
    },
    guid() {
      // http://www.ietf.org/rfc/rfc4122.txt
      let s = [];
      let hexDigits = "0123456789abcdef";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";
      return s.join("");
    }

  },
  mounted() {
    this.$socketInt.on("connect", () => {
      console.log("connected interactive");
    });

    if (this.user) {
      /*this.chatSocket.subscribe('chat.message.' + this.currentConference + '.' + this.user.id).bind('chat.message', async (message) => {
        console.log('user recevied', message)

        let is_in_same_room = location.hash == '#/app/chats/' + message.message.room_id
        if (message.userFrom.id != this.user.id) {
          if (location.hash == '#/app/chat') {
            this.getUnreadMessagesTotalCount();
          }
          if (location.hash == '#/app/mychats') {
            this.getUnreadMessagesTotalCount();
          }

          if (!is_in_same_room) {
            let msg = {
              time: message.message.created_at,
              content: message.message.text,
              redirect: '/app/chats/' + message.message.room_id,
              linkText: 'go to chat',
              from: message.userFrom.firstname + ' ' + message.userFrom.lastname,
              avatar: message.userFrom.avatar_id ? 'https://fileserver.documedias.systems/file/' + message.userFrom.avatar_id + '/face/130/130' : '',
              message: {
                title: ''//message.userMessage.user_id''
              }
            }

            this.getUnreadMessagesTotalCount();
            this.addMessage(msg)
          } else {
            //this.getUnreadMessagesTotalCount();

            await this.markMessageAsRead(message.message.id);
            //TODO:set message read
            await this.getRoomList();
          }

        }
      });*/
    }

  },
  async created() {

    this.setVM(this);
    if (!localStorage.getItem('uuid')) {
      localStorage.setItem('uuid', this.guid());
    }
    this.$socketInt.emit('join', this.currentConference);
    this.$socketInt.emit('getActiveSessions', {"congressId": this.currentConference});
    //TODO:join pushSocket
    this.pushSocket.emit("join", this.currentConference + '');


    if (isPlatform('mobile') && config.is_app) {
      await LocalNotifications.requestPermissions();//request local notifications permission
      await this.addLocalListeners();

      await this.registerLocalNotificationTypes();
      //test
      /*
     LocalNotifications.schedule({
      notifications: [
        {
          title: "Test Title",
          body: "Test Body",
          id: 1,
          schedule: {
            at: new Date(Date.now() + 1000 * 60), // in a minute
            repeats: true,
            every: "minute",
            allowWhileIdle: true,
          }
        }
      ]
    });
*/
      await this.addListeners();
      await this.registerNotifications();
      PushNotifications.removeAllDeliveredNotifications();
      await StatusBar.show();
      //SplashScreen.hide();
    }
    //executing push notification methods
    try {
      let res = await this.start();
      if (res) {
        this.showSyncToast();
        //this.$router.go();
        let time = await this.getItem('timeStamps_' + this.currentConference);
        console.log('time app', time)
        await this.setTime({name: 'timeStamps_' + this.currentConference, item: time});
        await this.getUnreadNews('posts');
      }
    } catch (err) {
      console.log(err)
    }

    if (isPlatform('mobile') && config.is_app) {
      await this.getDeliveredNotifications();
      FCM.getToken()
          .then((r) => console.log(`Token ${r.token}`))
          .catch((err) => console.log(err));
    }

    this.$socketInt.on("triggerUpdate", data => {
      console.log('updateMessage', data);
      this.showUpdateToast();
    });

    this.$socketInt.on("getActiveSessions", data => {
      console.log('sessions', data)
      this.setActiveSessions(data);
      if (data.length > 0) {
        //this.showSyncToast();
      }
    });
    //TODO:add subscriber
    this.pushSocket.on('pushreceived', (msg) => {
      console.log('PR',msg);
      msg.redirect = msg.message.redirect || '/app/posts/';//
      msg.linkText = 'See more';//

      this.addMessage(msg);
      setTimeout(() => {
        this.setUnreadNews(this.unreadNews + 1);
      }, 1000)

    });


    this.chat_enabled = await this.getSetting('chat_enabled');
    console.log('chat available', this.chat_enabled)

    this.video_chat_enabled = await this.getSetting('chat_enabled');
    console.log('video chat available', this.video_chat_enabled)


  },

  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        //UPDATE REMINDER EVERY HOUR
        let self = this;
        if (this.updateInterval) {
          clearInterval(this.updateInterval);
        }
        this.updateInterval = setInterval(() => {
          if(this.toast){
            this.toast.dismiss();
          }

          self.showUpdateToast();
        }, 900000);

      }
    },
    '$route': {
      immediate: true,
      async handler() {
      }
    }


  },
});
</script>


<style lang="scss">

@import "~bootstrap/scss/bootstrap";
@import "./assets/styles/ionicons.css";
@import './assets/styles/modern.scss';
@import './assets/styles/dashboard.scss';
@import './assets/styles/videochat.scss';
@import './assets/styles/exhibitor.scss';
@import './assets/styles/martin.scss';
@import './assets/styles/requests.scss';
@import './assets/styles/profile.scss';
@import './assets/styles/polls.scss';
@import './assets/styles/meetingsgrid.scss';

@import './assets/styles/changebrowser.scss';
@import './assets/styles/brand.scss';


@font-face {
  font-family: 'documedias-icons';
  src: url('./assets/fonts/documedias-icons.eot?y5ed3m');
  src: url('./assets/fonts/documedias-icons.eot?y5ed3m#iefix') format('embedded-opentype'),
  url('./assets/fonts/documedias-icons.ttf?y5ed3m') format('truetype'),
  url('./assets/fonts/documedias-icons.woff?y5ed3m') format('woff'),
  url('./assets/fonts/documedias-icons.svg?y5ed3m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-sponsor, .icon-aussteller {
  font-family: "documedias-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem !important;
}

.icon-sponsor:before {
  content: " \e904 ";
}

.icon-aussteller:before {
  content: " \e903 ";
}

ion-content {
  --padding-start: env(safe-area-inset-left); /* Apply safe area */
  --padding-end: env(safe-area-inset-right);
}

body.scanner-active {

  .scanlist-banner{
     display:none;
  }
  ion-app{
    background: transparent !important;
  }
  --background: transparent;
  --ion-background-color: transparent;
  .ion-page{
    background: transparent !important;
    ion-content{
      display:none;
    }
    .conf2go-controls{
      display:none;
    }
    .scan-controls{
      display: block;
    }
  }
  .qr-button{
    display: none;
  }
  .day-selector{
    display: none;
  }
  .sessionlist-searchbar{
    display: none;
  }
}

</style>
