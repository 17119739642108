<template>
  <nav class="navbar navbar-light bg-light fixed-top navbar-expand-lg toolbar" :class="{'branding-wrapper' :
  isConf2GoActive}" style="background: #ba2526 !important;"
  ><!--:style="{top: isIos ? '40px' : '0px'}"-->
    <div class="container-fluid" style="height: 100% !important">
      <div :style="{'margin-top': isIos ? ' 15px !important;' : '0px'}" style="z-index: 20">

        <a v-if="historyCount > 0 && !isConf2GoActive" @click="goBack()">
          <ion-icon class="mobile-activated menu-conference"
                    style="margin-bottom: 4px !important; font-size: 2rem; vertical-align:bottom"
                    :name="'chevron-back-outline'"></ion-icon>
          <ion-icon class="mobile-deactivated menu-conference"
                    style="margin-bottom: 4px !important; font-size: 2rem; vertical-align:bottom"
                    :name="'chevron-back-outline'" ></ion-icon>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar" style="margin-bottom: 4px;" v-if="!isConf2GoActive">

          <ion-icon class="mobile-activated menu-conference" style="font-size: 2rem; vertical-align:bottom"
                    :name="'menu-outline'"></ion-icon>
          <span v-if="unreadTotal > 0"
                class="position-absolute end-1   translate-middle badge rounded-pill bg-primary"
                style="top:20px;font-size:0.6rem">
                {{ unreadTotal }}
              </span>
        </button>
        <button class="btn btn-light update-button mobile-activated" style="background:transparent" type="button"
                @click="update" v-if="!isConf2GoActive">
          <div title="Check for updates">
            <ion-icon style="pointer-events:none;margin-right: 4px; font-size: 26px; vertical-align:bottom"
                      aria-label="Check for updates" title="Check for updates"
                      :name="'sync-outline'"
                      :style="{'animation': (syncActive ? '0.75s linear infinite spinner-border' : 'none')}"></ion-icon>
          </div>

        </button>


      </div>
      <div class="mobile-deactivated" :style="{'margin-top': isIos ? ' 15px !important;' : '0px'}" style="z-index: 20">

        <button class="btn btn-light update-button " style="background:transparent" type="button"
                @click="update" v-if="!isConf2GoActive">
          <div title="Check for updates">
            <ion-icon style="pointer-events:none;margin-right: 4px; font-size: 26px; vertical-align:bottom"
                      aria-label="Check for updates" title="Check for updates"
                      :name="'sync-outline'"
                      :style="{'animation': (syncActive ? '0.75s linear infinite spinner-border' : 'none')}"></ion-icon>
          </div>

        </button>
      </div>
      <router-link class="navbar-brand menu-conference mobile-deactivated" v-if="!isConf2GoActive"
                   style="height: 100% !important; padding-top: 0px; padding-bottom: 0px;" to="/app/dashboard">
        <ion-icon style="margin-right: 4px; font-size: 26px; vertical-align:bottom" :name="'home-outline'"
                  ></ion-icon>

      </router-link>
      <div class="navbar-brand menu-conference mobile-deactivated" v-if="!isConf2GoActive && multiConf"
           style="height: 100% !important; padding-top: 0px; padding-bottom: 0px;" @click="backToSociety()">
        <ion-icon style="margin-right: 4px; font-size: 26px; vertical-align:bottom" :name="'copy-outline'"></ion-icon>

      </div>
      <ion-icon class="mobile-deactivated"
                style="margin-bottom: 4px !important;color:red !important; margin-left:5px; font-size: 26px; vertical-align:bottom; "
                v-if="!onLine"
                :name="'cloud-offline-outline'"></ion-icon>

      <div class="navbar-brand menu-conference mobile-activated menu-top-title">
        <router-link class="menu-top-text menu-conference" to="/app/dashboard" v-if="!isConf2GoActive">
          <b :style="{'color':primaryColor}" data-bs-toggle="offcanvas"> {{ fullName }} </b>
        </router-link>
        <b v-else>
          <branding></branding>
        </b>
      </div>
      <div class="navbar-brand menu-conference mobile-deactivated menu-top-title" v-if="isConf2GoActive">
        <branding></branding>
      </div>
      <button class="btn btn-light navbar-brand menu-conference mobile-activated"
              style="height: 100% !important; padding-top: 0px; padding-bottom: 0px; background:transparent; border:none"
      >

        <ion-icon
            style="margin-bottom: 16px !important;color:red !important; margin-left:5px; font-size: 26px; vertical-align:bottom; "
            v-if="!onLine"
            :name="'cloud-offline-outline'"></ion-icon>
      </button>
      <button class="btn btn-light navbar-brand menu-conference mobile-deactivated"
              style="height: 100% !important; padding-top: 0px; padding-bottom: 0px; background:transparent; border:none"
      >

        <ion-icon
            style="margin-bottom: 0px !important;color:red !important; margin-left:5px; font-size: 26px; vertical-align:bottom; "
            v-if="!onLine"
            :name="'cloud-offline-outline'"></ion-icon>
      </button>
      <!--button @click="goToChat()" style="margin-bottom: 9px !important;" class="navbar-toggler"
               type="button" >
         <ion-icon style="margin-right: 4px; padding-bottom: 5px; font-size: 26px; vertical-align:bottom"
                   :name="'chatbubble-outline'"></ion-icon>
                     <IonLabel><span v-if="unreadTotal > 0"
           class="position-absolute top-0  badge rounded-pill bg-danger" style="right: 2px !important; top:2px !important">
                 {{ unreadTotal }}
               </span></IonLabel>

       </button-->
      <div class="offcanvas offcanvas-start canvas-mobile-offset" tabindex="-1" id="offcanvasNavbar"
           aria-labelledby="offcanvasNavbarLabel" :style="{top: isIos ? '47px' : '0px'}">
        <button type="button" class="btn-close text-reset btn-close-white mobile-activated" style="z-index: 9000;"
                data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
        <div class="canvas-profile mobile-activated"
             :style="{'background-image': menuheader ? getMenuBackgroundImage : ''}"
             :class="{'canvas-profile-background': !menuheader}">
        </div>
        <div style="position:relative;">
          <button type="button" class="btn btn-primary mobile-activated profile-button-mobile profile-button-login"
                  :style="{'background' : primaryColor + '!important', 'border': '1px solid' + primaryColor + '!important'}"
                  v-if="!user || !user.id" @click="login()"> {{ wordings[currentLanguage].LOGIN }}
          </button>
          <template v-if="user">
            <button type="button" class="btn btn-primary mobile-activated profile-button-mobile" v-if="user.id"
                    data-bs-dismiss="offcanvas"
                    @click="goToProfile()"
                    :style="{'background' : primaryColor + '!important', 'border': '1px solid' + primaryColor + '!important'}">
              {{
                user.firstName
              }} {{ user.lastName }}
            </button>
          </template>
          <template v-if="user">
            <button type="button" class="btn btn-primary mobile-activated logout-button-mobile" v-if="user.id"
                    @click="logOutAlert()" data-bs-dismiss="offcanvas"
                    :style="{'background' : primaryColor + '!important'}">
              <ion-icon
                  style="margin-left:5px; font-size: 24px;"
                  :name="'log-out-outline'"></ion-icon>
            </button>
          </template>
        </div>

        <div class="offcanvas-body custom-offcanvas-body">
          <div>
            <span>
                 <button class="btn btn-light mobile-update-button mobile-activated " type="button" @click="update"
                         :style="{'background' : primaryColor + '!important'}">
                   <small style="position: absolute; bottom:6px; left:10px">{{ versionString }}</small>
            {{ wordings[currentLanguage].DATA_UPDATE }}
            <ion-icon style="margin-right: 4px; font-size: 26px; vertical-align:bottom"
                      :name="'sync-outline'"
                      :style="{'animation': (syncActive ? '0.75s linear infinite spinner-border' : 'none')}"></ion-icon>
          </button>
                 </span>
            <span v-if="!isConf2GoActive && multiConf">
                 <button type="button" class="btn btn-primary mobile-activated change-button-mobile" v-if="user.id"
                         @click="backToSociety()" data-bs-dismiss="offcanvas">  <ion-icon
                     :name="'copy-outline'"></ion-icon> </button>
                </span>
          </div>


          <div class="d-flex pb-2">
            <input class="form-control me-2  mobile-activated" type="search"
                   :placeholder="wordings[currentLanguage].SEARCH" aria-label="Search"
                   v-model="searchterm" @keyup.enter="search()">
            <ion-icon class="mobile-activated mobile-search-icon"
                      :name="'search-circle-outline'" @click.prevent="search()" data-bs-dismiss="offcanvas"
                      data-bs-target="offcanvasNavbar"></ion-icon>
          </div>
          <template v-if="!isConf2GoActive && updateFinished">
            <ul class="navbar-nav justify-content-center flex-grow-1 pe-3 mobile-deactivated "
                style="margin-left: 20px;"
                v-for="link in menulinksTop" :key="link.id">
              <top-menu-item :link="link"></top-menu-item>
            </ul>
          </template>


          <ul class="navbar-nav justify-content-center flex-grow-1 mobile-activated" style=" width: 90%"
              v-for="link in menulinks" :key="link.id">
            <menu-item :link="link"></menu-item>

          </ul>
          <hr style="width: 100%" class="mobile-activated">
          <ul v-if="user" class="navbar-nav justify-content-end flex-grow-1 pe-3 mobile-activated"
              style="margin-left: 20px; width: 100%">
            <li v-if="user.id" class="nav-item toolbar-link">

            </li>
          </ul>

          <ul v-if="!user || !user.id" class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <button type="button" class="nav-item btn btn-primary mobile-deactivated desktop-user-button"
                    @click="login()">
              <ion-icon style="margin-right:10px; font-size: 24px;  vertical-align: middle;"
                        :name="'person-circle-outline'"></ion-icon>
              <div class="login-button-text"> {{ wordings[currentLanguage].LOGIN }}


              </div>
            </button>
          </ul>

          <ul v-if="user && user.id && !isConf2GoActive" class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <div class="d-flex search-box">
              <input class="form-control me-2 mobile-deactivated" type="search"
                     :placeholder="wordings[currentLanguage].SEARCH" aria-label="Search"
                     v-model="searchterm" @keyup.enter="search()">
              <ion-icon class="mobile-deactivated menu-conference" :style="{'color':primaryColor + '!important'}"
                        style="margin: 0px 8px 0 0; font-size: 40px; vertical-align:bottom"
                        :name="'search-circle-outline'" @click.prevent="search()"></ion-icon>
            </div>
            <li class="nav-item dropdown custom-dropdown account-dropdown mobile-deactivated">
              <a class="nav-link dropdown-toggle custom-nav-link" href="#" id="navbarDropdownMenuLink" role="button"
                 data-bs-toggle="dropdown" aria-expanded="false" :style="{'color':primaryColor + '!important'}">
                <ion-icon style="margin-right: 4px; font-size: 23px; vertical-align:bottom"
                          :style="{'color':primaryColor + '!important'}"
                          :name="'person-circle-outline'"></ion-icon>
                {{ user.firstName }} {{ user.lastName }}
                <!--span v-if="unreadNews + unreadTotal > 0"
                    class="position-absolute top-0 m-3 end translate-middle badge rounded-pill bg-primary">
                {{ unreadNews + unreadTotal }}
              </span-->
                <span v-if=" unreadTotal > 0"
                      class="position-absolute top-0 m-3 end translate-middle badge rounded-pill bg-primary">
                {{ unreadTotal }}
              </span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li>
                  <router-link class="dropdown-item custom-dropdown-item" to="/app/userinfo"> My Profile</router-link>
                </li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li>
                  <router-link class="dropdown-item custom-dropdown-item" to="/app/favorites"> My Congress
                  </router-link>
                </li>
                <!--li>
                  <hr class="dropdown-divider">
                </li>
                <li style="position:relative">
                  <router-link class="dropdown-item custom-dropdown-item" to="/app/chat"> Chat
                  </router-link>
                  <span v-if="unreadTotal > 0"
                        class="position-absolute top-0 end-0   translate-middle badge rounded-pill bg-primary">
                {{ unreadTotal }}
              </span>
                </li-->
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item custom-dropdown-item" @click="logOut()"> Log Out</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbarNews"
         aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header ">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        <span class="offcanvas-title">News</span>
      </div>
      <div class="offcanvas-body custom-offcanvas-body " style="background: #DCDFE0 !important">
        <NewsList></NewsList>
      </div>
    </div>
  </nav>
  <Toast></Toast>
  <Splash></Splash>
  <Password></Password>


  <!--ion-router-outlet id="main-content" animated="false">
  </ion-router-outlet-->
  <router-view id="main-content"></router-view>
  <!--/ion-split-pane-->

  <ChangeBrowser></ChangeBrowser>
</template>
<script>
import {
  alertController,
  //IonRouterOutlet,
  toastController,
} from "@ionic/vue";
import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
import {addIcons} from "ionicons";
import {
  personCircleOutline,
  chatbubbleOutline,
  syncOutline,
  logOutOutline,
  logInOutline,
  searchCircleOutline,
  homeOutline,
  chevronBackOutline,
  cloudOfflineOutline,
  menuOutline,
  copyOutline
} from "ionicons/icons";
import NewsList from "@/views/News/NewsList";
import Toast from "@/components/ToastContainer";
import Splash from "@/components/Splash";
import Password from "@/components/Password";

import ChangeBrowser from "@/components/ChangeBrowser";

addIcons({
  "sync-outline": syncOutline,
  "person-circle-outline": personCircleOutline,
  "log-out-outline": logOutOutline,
  "log-in-outline": logInOutline,
  "chatbubble-outline": chatbubbleOutline,
  "search-circle-outline": searchCircleOutline,
  "home-outline": homeOutline,
  "chevron-back-outline": chevronBackOutline,
  'cloud-offline-outline': cloudOfflineOutline,
  "menu-outline": menuOutline,
  'copy-outline': copyOutline
});
import {isPlatform} from '@ionic/vue';
const config = require('@/config')
import router from '@/router';
import {store} from "@/store";
import MenuItem from './MenuItem.vue';
import TopMenuItem from './TopMenuItem.vue';

export default {
  components: {
    //IonRouterOutlet,
    NewsList,
    Toast,
    Splash,
    Password,
    ChangeBrowser,
    MenuItem,
    TopMenuItem
  },
  data() {
    return {
      menulinks: [],
      menulinksTop: [],
      title: config.full_name,
      isConsultant: false,
      userConsultant: null,
      isDesktop: null,
      isIos: null,
      video_chat_enabled: false,
      confDate: config.conf_date,
      openSubBar: false,
      sublinks: null,
      time: null,
      appointmentsRole: null,
      searchterm: '',
      hideSearchBar: false,
      historyCount: 0,
      loginAlertOpen: false,
      onLine: navigator.onLine,
      showBackOnline: false,
      multiConf: config.is_multi_conf_app,
      versionString: config.version


    }
  },
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapGetters('auth', ['user']),
    ...mapState('splashscreen', ['showSplashscreen']),
    ...mapState('personalDB', ['unreadNews']),
    ...mapState('chat', ['currentChat', 'unreadTotal']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapGetters('currentConference', ['isSociety', 'isConf2GoActive']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion', 'primaryColor', 'menuheader']),

    getMenuBackgroundImage() {
      return this.menuheader ? 'url( ' + this.menuheader + ')' : 'url("@/assets/canvas-bs.png")';
    }
  },
  methods: {
    ...mapActions("settings", [
      "getSetting", 'getConfig'
    ]),
    ...mapActions('menulinks', ['getAllLinks', 'getParents']),
    ...mapActions('sync', ['start']),
    ...mapActions("exhibitors", ["getAllExhibitors"]),
    ...mapActions('menulinks', ['getAllLinks', 'getParents', 'getTopItems', 'getChildren']),
    ...mapActions('auth', ['GET_USERDATA']),
    ...mapMutations('splashscreen', ['setShowSplash']),
    ...mapActions("personalDB", ["connectPersonalDB", 'getItem', 'getItems', 'prepareFavoriteList', 'setTime', 'getUnreadNews']),
    ...mapMutations('personalDB', ['setUnreadNews']),
    ...mapActions('chat', ['getUnreadMessagesTotalCount']),
    ...mapActions('currentConference', ['backToSociety']),
    ...mapMutations('currentConference', ['setPrimaryColor', 'setMenuHeader', 'setAltFont', 'setIcon']),


    async changeSettings() {
      let pc = await this.getConfig('primary_color')
      let af = await this.getConfig('alt_font')
      let i = await this.getConfig('icon')
      let h = await this.getConfig('head')
      this.setPrimaryColor(pc)
      this.setAltFont(af)
      this.setIcon(i)
      this.setMenuHeader(h)
    },
    goBack() {
      let newCount = window.history.length - this.historyCount
      //this.$router.go(-1)
      window.history.back();
      /*if(this.historyCount > 0){
        this.$router.go(-1)
      }else{
        this.$router.push('/');
      }*/
    },

    async showSyncToast() {
      const toast = await toastController
          .create({
            message: 'Application is now up to date',
            duration: 2000,
            position: 'bottom',
            animated: 'true',
            color: 'primary',
          })
      return toast.present();
    },

    async update() {
      let res = await this.start();
      if (res) {
        this.showSyncToast();
      }
    },

    updateOnlineStatus(e) {
      const {
        type
      } = e;
      this.onLine = type === 'online';
    },

    async search() {

      var myOffcanvas = document.getElementById('offcanvasNavbar');
      if (myOffcanvas.classList.contains("show")) {
        myOffcanvas.classList.remove("show");
      }
      document.querySelectorAll('.offcanvas-backdrop').forEach(function (a) {
        a.remove()
      })
      this.$router.push('/app/search/' + this.searchterm);
      this.searchterm = ''
    },


    async prepareLinks() {
      this.menulinks = (await this.getParents()).sort((a, b) => a.order - b.order);
    },
    async prepareLinksTop() {
      this.menulinksTop = (await this.getTopItems()).sort((a, b) => a.order - b.order);
    },

    goToChat() {
      if (this.user && this.user.id) {
        this.$router.push('/app/chat/');
      } else {
        this.chatAlert()
      }
    },

    async chatAlert() {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'You need to be logged in in order to access the chat.',
            message: 'Do you want to log in?',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                id: 'cancel-button',
                handler: blah => {
                  console.log('Confirm Cancel:', blah)
                },
              },
              {
                text: 'Confirm',
                id: 'confirm-button',
                handler: () => {
                  this.login()
                },
              },
            ],
          });
      return alert.present();
    },

    async loginByToken(tokenSent) {
      //check for token in path
      let token = tokenSent
      if (token) {

        localStorage.setItem("x-api-key", token);

        let userRequest = await store.dispatch("auth/GET_USERDATA");
        //this.user = userRequest.data;

        let tmpUser = userRequest.data;

        if (tmpUser) {

          await store.commit("auth/SET_USER", tmpUser);
          localStorage.setItem('user', JSON.stringify(tmpUser));
        }
        //splice the path
        window.history.pushState(
            {},
            document.title,
            '/'
        );
        router.push({name: 'Dashboard'});

      } else {
        router.next();
      }
      router.next();

    },


    async login() {
      console.log("login")
      let self = this
      localStorage.setItem('lastPageBeforeLogin', location.hash);
      //let frontendKey = config.auth_key;
      if (isPlatform('ios') && typeof cordova != 'undefined') {
        let ref = cordova.InAppBrowser.open("https://auth.documedias.com/application/" + config.auth_dev_key + '?logout=true', '_blank', 'location=yes', 'clearcache=no', 'clearsessioncache=no'); // eslint-disable-line

        let redirect = (param) => {
          console.log('PARAM', param)
          if (param && param.indexOf('token=') != -1) {
            let token = param.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        }

        ref.addEventListener('loaderror', function (event) {
          console.log('PARAM', event)
          /*ref.executeScript({code: "document.redirectLink = (document.getElementById('redirect').href);"}, ((link) => {
            redirect(link.toString());
          }));*/
          console.log('PARAM', event.url)
          if (event.url.indexOf('token=')) {
            let token = event.url.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        });
        ref.addEventListener('loadstart', function (event) {
          console.log('PARAM', event)
          /*ref.executeScript({code: "document.redirectLink = (document.getElementById('redirect').href);"}, ((link) => {
            redirect(link.toString());
          }));*/
          console.log('PARAM', event.url)
          if (event.url.indexOf('token=')) {
            let token = event.url.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        });


      } else if (isPlatform('android') && typeof cordova != 'undefined') {

        let ref = cordova.InAppBrowser.open("https://auth.documedias.com/application/" + config.auth_dev_key, '_blank', 'location=yes', 'clearcache=no', 'clearsessioncache=no'); // eslint-disable-line
        ref.addEventListener('loaderror', function (event) {

          if (event.url.indexOf('token=')) {
            let token = event.url.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        });
      } else {
        console.log("essta")
        //window.location.href = "https://auth.documedias.com/application/" + config.auth_dev_key
        window.location.href = "https://auth.documedias.com/application/" + (process.env.NODE_ENV == 'production' ? config.auth_key : config.auth_dev_key) + '?logout=true'

      }

    },

    goToProfile() {
      this.$router.push('/app/userinfo');
    },


    async logOutAlert() {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Log Out',
            message: 'Do you want to log out?',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                id: 'cancel-button',
                handler: blah => {
                  console.log('Confirm Cancel:', blah)
                },
              },
              {
                text: 'Confirm',
                id: 'confirm-button',
                handler: () => {
                  console.log('Confirm Okay')
                  this.logOut()
                },
              },
            ],
          });
      return alert.present();
    },

    async logOut() {
      await store.commit("auth/SET_USER", null);
      localStorage.setItem('x-api-key', '');

      localStorage.setItem('user', '')

      window.location.reload()
      this.$router.push('/app/dashboard');
    },

    bannerClick() {
      //router.push({name: 'Dashboard'});
      this.$router.push('/app/dashboard');
    },
    async presentNotAllowedAlert() {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Not allowed',
            message: 'You do not have permission to see the content',
            buttons: [
              {
                text: 'Ok',
                id: 'confirm-button',
                handler: () => {
                  console.log('Confirm Okay')
                  this.login()
                },
              },
            ],
          });
      return alert.present();
    }


  },

  async created() {

    this.isDesktop = isPlatform('desktop');
    this.isIos = isPlatform('ios');
    if (localStorage.getItem('x-api-key') != '') {

      let userRequest = await store.dispatch("auth/GET_USERDATA");

      if (userRequest)
        await store.commit("auth/SET_USER", userRequest.data);

      //this.appointmentsRole = await store.dispatch("auth/GET_APPOINTMENT_ROLE");
    } else {
      // this.login();
    }
    //TODO:setting in config restricted and restricted marker
    if (this.user) {

      //await this.getUnreadMessagesTotalCount();
      await this.connectPersonalDB();
    }
    if (!isPlatform('mobile') || !config.is_app) {
      if (!this.user) {
        //this.login();
      } else if (this.user && this.user.user_no_virt_access) {

        //this.presentNotAllowedAlert();
      }
    }

    if (isPlatform('mobile') && config.is_app) {
      if (!this.user) {
        console.log("You need to login in order to access all data'")
        /*const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: 'Login required',
              message: 'You need to login in order to access all data',
              buttons: [
                {
                  text: 'Cancel',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Go to login',
                  id: 'confirm-button',
                  handler: () => {
                    console.log('Confirm Okay')
                    this.login();
                  },
                },
              ],
            });
        return alert.present();*/

      } else if (this.user && !this.user.user_tn_marker) {
        //this.presentNotAllowedAlert();
      }
    }


    this.setShowSplash(true)
    console.log(this.user)
    this.prepareLinks();
    this.prepareLinksTop();

  },

  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },

  watch: {
    updateFinished: async function (newVal) {
      console.log('FINISH')
      if (newVal) {
        await this.prepareLinks();
        await this.prepareLinksTop();

        await this.changeSettings()
        //shows splash after every update
        //this.setShowSplash(true)


        //TODO: comment in for login
        /*if (isPlatform('mobile')){
          if (!this.user) {
            alert('You need to login in order to access all data')
            this.login();
          } else if (this.user && !this.user.user_tn_marker) {
            this.presentNotAllowedAlert();
          }
        }*/
      }
    },
    '$route': {
      immediate: true,
      async handler(route) {

        if (route.name == 'Dashboard') {
          this.historyCount = 0;
        } else {
          this.historyCount += 1;
          console.log('history', this.historyCount)
        }
        if (route.name == 'Search with term') {
          this.hideSearchBar = true;
        } else {
          this.hideSearchBar = false;
        }
      }
    },
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000);
      }
    }
  }

};
</script>
<style>
.menu-content-open {
  pointer-events: unset !important;
}

.menu-content.menu-content-open {
  z-index: 20;
}

.ion-menu-content-area {
  --padding-start: 0px;
  --padding-end: 0px;
}

</style>
<style lang="scss" scoped>
$conf-color: #10528E;
.branding-wrapper {
  color: #fff !important;
  //background: url(../img/splash-bg.jpg) no-repeat center center fixed;
  background-color: $conf-color !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  --background: -moz-linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* FF3.6-15 */
  --background: -webkit-linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* Chrome10-25,Safari5.1-6 */
  --background: linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#333F6A88', endColorstr='#336D8CA2', GradientType=1); /* IE6-9 fallback on horizontal gradient */


  .branding-intro {
    width: 100%;
    max-width: 600px;
    max-height: 30px;
  }

  #branding-intro-logo-image path {

  }

  #branding-intro-logo-text path {
    stroke: #fff;
    stroke-dasharray: 2200;
    stroke-dashoffset: 1000;
    animation: dash-text 2s ease-out forwards 1;
    transition: fill .2s ease-out;
  }
}
</style>
