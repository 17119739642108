<template>
  <ion-header>
    <ion-toolbar :style="{'--background': color}">
      <ion-title size="small" :style="{ 'color':font}"></ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content className="ion-padding">

    <ion-card>
      <img class="p-3" :src="head"/>
      <ion-card-header>
        <ion-card-subtitle>{{ title }}</ion-card-subtitle>
        <ion-card-title>
          <ion-progress-bar  type="indeterminate" v-if="syncActive && currentProgress == 0"></ion-progress-bar>
          <ion-progress-bar color="primary" v-if="syncActive && currentProgress > 0"
                            :value="currentProgress"></ion-progress-bar>

          <small class="ion-padding checking-data" v-if="syncActive && downloadSize === 0">{{ wordings[currentLanguage].CHECKING_DATA }}</small>
          <small class="ion-padding checking-data" v-if="syncActive && downloadSize && downloadLabel">{{ wordings[currentLanguage].DOWNLOAD }}
            {{ downloadSize }} {{ downloadLabel }} - {{ (currentProgress * 100).toFixed(2) }}%</small>
          <div style="width:100%; text-align:center">
            <ion-spinner></ion-spinner>
          </div>
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        {{description}}

      </ion-card-content>
    </ion-card>

  </ion-content>
</template>

<script>
import {IonContent, IonHeader, IonTitle, IonToolbar} from '@ionic/vue';
import {defineComponent} from 'vue';
import { mapState} from "vuex";

export default defineComponent({
  name: 'ConfLoadModal',
  props: ['title', 'color', 'font', 'icon', 'head','description'],
  data() {
    return {
      content: 'Content',
    };
  },
  created() {
    console.log(this.title, this.color, this.font, this.icon, this.head)
  },
  components: {IonContent, IonHeader, IonTitle, IonToolbar},
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress','downloadLabelText']),
    ...mapState('wordings',['wordings', 'currentLanguage']),
  },
});
</script>
