<template>
  <svg class="branding-intro" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2596 632"
       enable-background="new 0 0 2596 632" xml:space="preserve">
              <g id="branding-intro-logo-text">
                <path fill="rgba(255,255,255,0)"
                      d="M2187.394 538.712c-16.096 25.641-40.235 48.214-72.417 67.712 -32.187 19.504-72.966 29.253-122.331 29.253 -63.825 0-115.037-18.68-153.63-56.051 -38.599-37.366-57.892-90.553-57.892-159.562V238.408c0-68.46 18.616-121.513 55.847-159.153 37.231-37.64 86.532-56.46 147.902-56.46 66.005 0 115.51 16.148 148.516 48.431 33 32.284 50.183 78.535 51.551 138.742l-0.818 2.455h-109.239c-0.818-33.613-8.253-58.142-22.298-73.587 -14.051-15.445-35.53-23.167-64.438-23.167 -28.37 0-50.598 10.037-66.689 30.103 -16.097 20.067-24.139 50.572-24.139 91.525V419.95c0 41.77 8.113 72.691 24.344 92.757 16.225 20.068 39.891 30.098 70.985 30.098 23.18 0 40.3-3.133 51.346-9.41 11.047-6.271 20.112-15.547 27.207-27.821V398.789h-78.554v-81.827h194.748V538.712z"/>
                <path fill="rgba(255,255,255,0)"
                      d="M2220.566 378.741c0-59.459 15.752-108.076 47.255-145.856 31.503-37.775 75.62-56.665 132.355-56.665 56.729 0 100.916 18.89 132.559 56.665 31.638 37.781 47.459 86.397 47.459 145.856v54.824c0 60.008-15.822 108.695-47.459 146.061 -31.644 37.371-75.69 56.051-132.15 56.051 -57.01 0-101.26-18.68-132.764-56.051 -31.503-37.366-47.255-86.053-47.255-146.061V378.741zM2336.351 433.398c0 34.151 4.769 60.917 14.32 80.313 9.545 19.396 26.185 29.092 49.914 29.092 22.912 0 39.207-9.761 48.892-29.297 9.679-19.53 14.524-46.232 14.524-80.108v-54.9c0-33.327-4.845-59.894-14.524-79.699 -9.685-19.805-26.12-29.706-49.301-29.706 -23.186 0-39.622 9.971-49.301 29.911 -9.685 19.945-14.524 46.445-14.524 79.495V433.398z"/>
                <path fill="rgba(255,255,255,0)"
                      d="M1728.458 617.362h-351.855V538.4l162.426-205.794c21.544-30.603 37.027-57.241 46.437-79.921 9.41-22.682 14.115-43.311 14.115-61.895 0-25.412-4.91-45.906-14.729-61.478 -9.819-15.573-24.004-23.366-42.55-23.366 -19.095 0-33.754 9.448-43.982 28.346 -10.228 18.896-15.343 43.675-15.343 74.347h-113.33l-0.818-2.046c-1.093-54.006 14.179-99.759 45.823-137.264 31.638-37.5 74.188-56.256 127.65-56.256 54.549 0 97.099 15.822 127.65 47.46 30.545 31.644 45.823 74.737 45.823 129.286 0 36.553-8.527 70.371-25.571 101.465 -17.049 31.094-45.484 71.739-85.304 121.922l-77.735 111.284h201.294V617.362z"/>
                <path fill="rgba(255,255,255,0)"
                      d="M375.524 439.185l1.14 2.279c0.504 60.786-13.932 106.629-43.31 137.527 -29.383 30.904-72.693 46.349-129.929 46.349 -57.242 0-102.956-17.534-137.148-52.618 -34.192-35.077-51.288-84.778-51.288-149.115V258.348c0-64.075 16.526-113.783 49.578-149.115s77.312-52.997 132.779-52.997c58.756 0 103.146 15.706 133.158 47.109 30.013 31.408 45.523 77.882 46.539 139.427l-1.14 2.279H271.808c1.52-36.353-3.419-62.536-14.816-78.552s-31.283-24.023-59.646-24.023c-24.314 0-42.93 9.509-55.847 28.522 -12.917 19.013-19.375 47.785-19.375 86.323v166.174c0 39.049 6.898 68.077 20.705 87.09 13.801 19.013 34.002 28.517 60.596 28.517 26.084 0 44.26-7.534 54.517-22.605 10.258-15.065 14.751-40.84 13.487-77.312H375.524z"/>
                <path fill="rgba(255,255,255,0)"
                      d="M391.131 386.757c0-55.213 14.627-100.355 43.88-135.438 29.253-35.076 70.218-52.618 122.901-52.618 52.677 0 93.708 17.542 123.091 52.618 29.377 35.083 44.07 80.225 44.07 135.438v50.908c0 55.721-14.692 100.93-44.07 135.628 -29.383 34.703-70.283 52.048-122.711 52.048 -52.938 0-94.028-17.344-123.281-52.048 -29.253-34.698-43.88-79.907-43.88-135.628V386.757zM498.645 437.51c0 31.711 4.429 56.566 13.297 74.576 8.862 18.011 24.314 27.015 46.349 27.015 21.275 0 36.406-9.065 45.399-27.204 8.988-18.136 13.487-42.93 13.487-74.386v-50.978c0-30.945-4.499-55.616-13.487-74.006 -8.994-18.391-24.254-27.584-45.779-27.584 -21.531 0-36.791 9.261-45.779 27.774 -8.994 18.522-13.487 43.126-13.487 73.817V437.51z"/>
                <path fill="rgba(255,255,255,0)"
                      d="M849.713 206.3l4.559 56.607c11.397-20.259 25.388-36.026 41.98-47.299 16.586-11.267 34.886-16.906 54.897-16.906 34.192 0 61.1 13.173 80.731 39.511 19.625 26.344 29.443 67.756 29.443 124.231v254.92H953.048V362.151c0-28.397-3.989-48.36-11.967-59.9 -7.978-11.54-20.325-17.309-37.041-17.309 -9.628 0-18.491 2.54-26.594 7.61 -8.108 5.07-14.947 12.043-20.515 20.913v303.899H749.037V206.3H849.713z"/>
                <path fill="rgba(255,255,255,0)"
                      d="M1122.138 617.362V284.561h-50.528V206.3h50.528v-45.589c0-46.093 11.901-81.615 35.712-106.565 23.804-24.944 57.236-37.421 100.296-37.421 8.358 0 16.716 0.694 25.074 2.072 8.358 1.382 17.98 3.33 28.87 5.835l-9.115 82.132c-5.069-1.01-9.943-1.9-14.627-2.659 -4.689-0.76-10.198-1.14-16.526-1.14 -13.677 0-24.064 4.98-31.153 14.929 -7.094 9.949-10.637 24.113-10.637 42.478V206.3h66.104v78.261h-66.104v332.801H1122.138z"/>
              </g>
            </svg>

</template>

<script>
import {} from "@ionic/vue";

export default {
  components: {},
  data() {
    return {
      isDesktop: null,
    }
  },
  computed: {},
  methods: {},
  async created() {
  }
};
</script>
<style lang="scss">
.branding-intro {
  width: 100%;
  max-width: 600px;
  max-height: 30px;
}

#branding-intro-logo-image path {

}

#branding-intro-logo-text path {
  stroke: #fff;
  stroke-dasharray: 2200;
  stroke-dashoffset: 1000;
  animation: dash-text 2s ease-out forwards 1;
  transition: fill .2s ease-out;
}


</style>
