<template>
  <div class="custom-toast-container" aria-live="polite" aria-atomic="true" v-if="pushmessages.length > 0">
    <div class="custom-toast-wrapper">
      <div class="custom-toast" role="alert" aria-live="assertive" aria-atomic="true" v-for="(message, index) in pushmessages" :key="message.id" :style="{'animation': currentDeletion == index ? 'fade_in_show 0.4s' : 'none'}">
        <div class="custom-toast-header">
          <img v-if="message.avatar" :src="message.avatar " class="custom-toast-header-img" alt="...">
          <img v-if="!message.avatar" src="@/assets/icon/icon.png" class="custom-toast-header-img" alt="...">
          <strong class="custom-toast-header-title">{{message.from ? message.from : 'New message'}}</strong>
          <small class="custom-toast-header-addition">{{ moment(message.time).format('dd MMM DD HH:mm')}}</small>
          <button type="button" class="custom-toast-header-close button button-small button-icon " data-dismiss="toast" aria-label="Close" @click="markRead(index)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="custom-toast-body" >
          <div class="custom-toast-body-title" v-html="message.message.title" v-if="message.message.title"></div>
          <div class="custom-toast-body-content" v-html="message.content"></div>
          <template v-if="message.redirect">
            <router-link :to="message.redirect" @click="markRead(index)" style="text-decoration: none; font-size:0.9rem"><div>{{message.linkText}}</div></router-link>
          </template>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue';
import moment from 'moment';
import {mapMutations, mapState} from "vuex";

export default defineComponent({
  name: 'Toast',
  data() {
    return {

      currentDeletion: -1
    }
  },
  components: {
  },
  computed: {
    ...mapState('pushmessages', ['pushmessages'])
  },
  methods: {
    ...mapMutations('pushmessages', ['addMessage', 'markAsRead']),
    markRead :function(index){
      this.currentDeletion = index;
      setTimeout(()=>{
        this.markAsRead(index);
        this.currentDeletion = -1
      },200);

    }
  },
  async created() {
    this.moment = moment;
  }
});
</script>
<style lang="scss" scoped>
.custom-toast-container {
  position: absolute;
  top:calc(env(safe-area-inset-top) + 40px);
  right: 0px;
  height: 95%;
  overflow: auto;
  width: 320px;
  z-index: 10;

  @keyframes fade_in_show {
    0% {
      opacity: 1;
      transform: scale(1)
    }

    100% {
      opacity: 0;
      transform: scale(0)
    }
  }

  .custom-toast-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column-reverse;
  }

  .custom-toast {
    background: white;
    color:#000;
    margin: 10px;
    min-height: 60px;
    max-width: 300px;
    min-width: 280px;
    width: 300px;
    border-radius: 5px;
    //box-shadow: 4px 2px 5px grey;
    box-shadow: 0px 0px 3px grey;

    .custom-toast-header {
      background: lightgrey;
      height: 30px;
      position: relative;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .custom-toast-header-img {
        display: inline-block;
        height: 30px;
        width: 30px;
        border-top-left-radius: 5px;
      }

      .custom-toast-header-title {
        position: absolute;
        top: 5px;
        left: 35px;
        font-size: 0.9rem;
      }

      .custom-toast-header-addition {
        position: absolute;
        top: 5px;
        right: 25px;
        font-size: 0.7rem;
      }

      .custom-toast-header-close {
        position: absolute;
        top: 2px;
        right: 0;
        background: lightgrey;
        border-top-right-radius: 5px;

        span {
          background: lightgrey;
          font-size: 16px;
          color:#000;
        }
      }
    }

    .custom-toast-body {
      padding: 5px 10px;
      font-size: 1.1rem;

      .custom-toast-body-title {
        font-weight: bold;
      }

      .custom-toast-body-content {
        img {
          max-width: 100%;
        }
      }

    }
  }
}
</style>
